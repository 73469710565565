<template>
  <div class="program-detail">
    <div class="detail-header">
      <div
        class="detail-banner"
        :style="{
          'background-image': 'url(' + programDetail.bannerUrl + ')',
          'background-repeat': 'no-repeat',
          'background-size': 'cover',
        }"
      ></div>
      <div class="detail-card">
        <div class="program-img" >
          <img :src="`${programDetail.business.businessLogoUrl}`" alt="" />
        </div>
        <div class="program-info">
          <div class="company-content">
            <div class="company-name">
              <p>{{ programDetail.name }}</p>
              <span>{{ programDetail.type }}</span>
            </div>
            <div class="website-info">
              <a href="https://www.reddit.com" target="_blank"
                >https://www.reddit.com</a
              >
            </div>
          </div>
        </div>
        <div class="sendreport">
          <Calltoaction
            :text="'Enviar Reporte'"
            :style="'calltoaction-gradient'"
            :whereto="'/app/' + programId + '/report'"
          />
        </div>
      </div>
    </div>
    <router-view />
  </div>
</template>

<script>
import Calltoaction from "@/components/calltoaction.vue";
import store from "@/store";
export default {
  name: "programDetail",
  data() {
    return {
      detail: {},
    };
  },
  components: {
    Calltoaction,
  },
  computed: {
    programDetail() {
      return this.$store.state.programDetail.content;
    },
    programId(){
      return this.$route.params.id
    }
  },
  beforeRouteEnter(to, from, next) {
    store
      .dispatch("getProgramDetail", to.params.id)
      .then(() => {
        next();
      })
      .catch((e) => {
        console.log(e);
      });
  },
};
</script>

<style lang="scss">
.program-detail {
  .detail-header {
    position: relative;
    .detail-banner {
      height: 280px;
    }
    .detail-card {
      position: absolute;
      top: 0;
      width: 1200px;
      height: 200px;
      border-radius: 6px;
      background-color: white;
      top: 40px;
      left: 350px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      padding: 25px;
      .program-img {
        margin-right: 40px;
        height: 100%;
        img {
          height: 100px;
          width: 100px;
          border-radius: 100%;
        }
      }
      .program-info {
        width: 100%;
        height: 100%;
        .company-name {
          margin-bottom: 40px;
          p {
            margin: 0;
          }
        }
        a {
          color: #2d68f4;
          padding: 0;
        }
      }
      .sendreport {
        width: 241px;
      }
    }
  }
  .detail-content {
    width: 1200px;
    margin: 60px auto;
    .scope-container {
      margin-top: 30px;
      padding-bottom: 20px;
      .scope-content {
        padding: 0;
        .scope-list {
          table {
            width: 100%;
            td {
              border-bottom: 1px solid #e9e9e9;
              padding: 15px;
              .status {
                text-align: center;
                border-radius: 4px;
                color: white;
              }
              .critical {
                background: #df2935;
              }
              .bounty {
                background: #09a571;
              }
            }
          }
          .out-of-scope {
            font-weight: 600;
            padding: 0 20px;
          }
        }
      }
    }
    .detail-card-container {
      border: 1px solid #e9e9e9;
      .detail-card-header {
        width: 100%;
        background: #f7f8f9;
        padding: 20px;
        border-bottom: 1px solid #e9e9e9;
        h4 {
          margin: 0;
        }
      }
      .detail-card-content {
        padding: 20px;
      }
    }
  }
}
</style>
